.kensaRadio {
    &_Group {
        cursor: pointer;
        text-align: center;
    }
    &_Label {
        cursor: pointer;
        display: block;
        padding-top: 3rem;
        position: relative;
        font-weight: bold;
        font-weight: bold;
        img {
            height: 2rem;
            object-fit: cover;
        }
        svg {
            display: inline-block;
            fill: #FFF;
            position: absolute;
            top: 3px;
            left: 50%;
            transform: translate(-50%, 0);
            transition: all .3s ease;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 1px solid #ddd;
            transform: translate(-50%, 0);
            transition: all .3s ease;
        }
    }
    &_Input:checked+label::before {
        background-color: #f8cc37;
    }
    &_Input:checked+label svg {
        fill: #000;
    }
    &_Name {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 20px;
    }
    &_Sub {
        display: block;
        font-size: 14px;
        color: #f8cc37;
    }
}