.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes ldio-rtnxkhsntbk {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ldio-rtnxkhsntbk>div>div {
    position: absolute;
    border-radius: 50%;
}

.ldio-rtnxkhsntbk>div>div:nth-child(1) {
    top: 80px;
    left: 80px;
    width: 40px;
    height: 40px;
    background: #f8cc37;
}

.ldio-rtnxkhsntbk>div>div:nth-child(2) {
    top: 82.8px;
    left: 94px;
    width: 12px;
    height: 12px;
    background: #ffffff;
    animation: ldio-rtnxkhsntbk 1s linear infinite;
    transform-origin: 6px 17.2px;
}

.loadingio-spinner-disk-b6avn49nelc {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}

.ldio-rtnxkhsntbk {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-rtnxkhsntbk div {
    box-sizing: content-box;
}


/* generated by https://loading.io/ */

.kensaForm {
    &_Container {
        height: 100%;
        display: flex;
        flex-flow: column;
        overflow: hidden;
    }
    &_Content {
        padding: 1rem;
    }
}

.kensaForm {
    &_Title {
        margin: 0;
        font-size: 20px;
        margin-bottom: 1rem;
    }
}

#modalCalendar .modal-dialog {
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    #modalCalendar .modal-dialog {
        max-width: 800px;
    }
}


/* Example Styles for React Tags*/

div.ReactTags__tags {
    position: relative;
}


/* Styles for the input */

div.ReactTags__selected {
    display: flex;
    flex-flow: row wrap;
}

div.ReactTags__tagInput {
    flex: 0 0 auto;
    max-width: 100%;
    flex-grow: 1;
    border-radius: 2px;
    display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 33px;
    margin: 0;
    font-size: 14px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
}


/* Styles for selected tags */

div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px 5px;
    cursor: move;
    border-radius: 2px;
}

.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
    border: none;
    outline: 0;
}


/* Styles for suggestions */

div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.form-control.invalid {
    border-color: #dc3545;
    &:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    }
}

.invalid-feedback {
    display: block;
}

.invalid-select {
    .kensaSelect__control {
        outline: #dc3545 !important;
        border-color: #dc3545 !important;
        &:focus {
            border-color: #dc3545 !important;
            box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
        }
    }
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    font-size: 20px;
    color: #333;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    font-size: 20px;
    color: #333;
}