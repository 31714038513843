.kensaOrder_List {
  padding: 15px;

  &_Item {
    display: block;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #eee;
    color: #000;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      color: #000;
      text-decoration: none;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    }

    &--new {
      background: #e1ffe8 !important;
    }

    &--expire {
      background: #f3d1d1 !important;
    }
  }
}

@media screen and (max-width: 575px) {
  .kensaOrder_List {
    &_Item {
      &--first {
        .time {
          font-weight: bold;
        }
      }
      &--even {
        background: #fafafa;
      }

      &--odd {
        background: #fff;
      }
    }
  }
}
