.kensaPolicy {
    &_Table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 1rem;
        tr td,
        tr th {
            text-align: center;
        }
    }
}