.kensaTech {
    &_Info {
        font-weight: bold;
    }
    &_Name {
        font-size: 14px;
    }
    &_Image {
        overflow: hidden;
        border-radius: 50%;
        img {
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            height: 120px;
            width: 120px;
            margin-bottom: 1rem;
            object-fit: cover;
        }
    }
    &_Phone {
        font-size: 12px;
    }
}