.kensaFooter {
    background: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    padding: 0.5rem 1rem;
    line-height: 40px;
    text-align: center;
    &_Top {
        margin-bottom: 66px;
        @media screen and (min-width: 768px) {
            margin-bottom: 0;
        }
    }
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: unset;
    }
}

.kensaCopyright {
    font-weight: bold;
    color: #333;
}

.kensaPrivacy {
    a {
        padding-left: 1rem;
        font-weight: bold;
        font-size: 14px;
        color: #333;
    }
}