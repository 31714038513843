.kensaOrder {
  &_Content {
      width: 100%;
      padding: 2rem 2rem 1rem;
  }
  &_Card {
      display: block;
      margin-bottom: 1rem;
      padding: 1rem;
      border: 1px solid #ddd;
      background: #FFF;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
      text-align: center;
      color: #333;
      transition: all .3s ease;
      &_Text {
          text-decoration: none;
          font-weight: bold;
          text-transform: uppercase;
      }
      &_Expertise {
          color: #FFF;
          background: #4f85fb;
          border-color: #4f85fb;
          &:hover {
              background: #FFF;
              color: #4f85fb;
          }
      }
      &_CheckOrigin {
          color: #FFF;
          background: #fb874f;
          border-color: #fb874f;
          &:hover {
              background: #FFF;
              color: #fb874f;
          }
      }
      &_FindBike {
          color: #FFF;
          background: #fd4c5b;
          border-color: #fd4c5b;
          &:hover {
              background: #FFF;
              color: #fd4c5b;
          }
      }
      &_Search {
          color: #FFF;
          background: #5fd4ce;
          border-color: #5fd4ce;
          &:hover {
              background: #FFF;
              color: #5fd4ce;
          }
      }
  }
}