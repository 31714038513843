.kensaBody {
  min-height: calc(100vh - 56px);
  padding-top: 80px;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  &_Container {
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    max-width: 1200px;
  }
  &_Title {
    margin: 0;
    text-align: center;
    font-size: 24px;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
  }
  &_Content {
    max-width: 100%;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-flow: row wrap;
    &Limit {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &_Left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &_Right {
    border-top: 1px solid #ddd;
    flex: 0 0 100%;
    width: 100%;
  }
  &_Full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .kensaBody {
    &_Left {
      flex: 0 0 calc(100% - 350px);
      max-width: calc(100% - 350px);
    }
    &_Right {
      flex: 0 0 350px;
      max-width: 350px;
      border-top: none;
      border-left: 1px solid #ddd;
    }
  }
}
