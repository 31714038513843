.kensaTooltip {
    &_Toggle {
        position: relative;
        border: none;
        outline: none;
        width: auto;
        height: auto;
        line-height: 1;
        padding: 0 5px;
        display: inline-block;
        background: #FFF;
    }
    &_Container {
        display: none;
        position: fixed;
        font-size: 14px;
        z-index: 99999;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.1);
        &_Show {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media screen and (min-width: 768px) {
            width: 200px;
            background: none;
            left: auto;
            right: auto;
            bottom: auto;
            top: auto;
        }
    }
    &_Content {
        position: relative;
        padding: 23px 7px 7px 8px;
        width: 100%;
        max-width: 300px;
        background: #f8cc37;
        box-shadow: 0 0 3px rgba(0, 0, 0, .3);
        animation-duration: 0.3s;
        @media screen and (min-width: 768px) {
            background: #f8cc37;
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: -7px;
                width: 15px;
                height: 15px;
                background: #f8cc37;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
                transform: translateX(-50%) rotate(45deg);
            }
        }
    }
    &_Body {
        word-break: break-word;
        white-space: pre-line;
    }
    &_Close {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        outline: none;
        width: auto;
        height: auto;
        line-height: 1;
        padding: 0.25rem;
    }
}