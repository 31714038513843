.kensaCoupon {
    &_Button {
        background: none;
        border: none;
        outline: none;
        color: blue;
        padding: 0;
    }
    &_Col {
        flex: 0 0 auto;
        max-width: 100%;
        span {
            line-height: 30px;
        }
    }
    &_Remove {
        cursor: pointer;
        position: relative;
        top: -3px;
        border: none;
        outline: none;
        width: auto;
        height: auto;
        line-height: 1;
        padding: 0 5px;
        display: inline-block;
        background: #fff;
    }
}