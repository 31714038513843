.kensaPrepay {
    &_Box {
        background: #fff4e5;
        border: 1px solid #fff4e5;
        padding: 1rem 2rem;
        text-align: center;
        font-weight: bold;
        width: 100%;
        display: block;
        margin-bottom: 2rem;
    }
    &_Title {
        font-size: 12px;
        height: 36px;
        text-transform: uppercase;
        margin-bottom: 0.25rem;
        color: #000;
    }
    &_Value {
        font-size: 16px;
    }
    &_Color {
        color: #28a745;
    }
}