.calendarContainer {
    display: flex;
    flex-flow: row wrap;
}

.calendarCol {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding: 10px;
    @media screen and (min-width: 768px) {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 10px;
    }
}

.calendarTime {
    cursor: pointer;
    border: 1px solid #72777a;
    color: #333;
    font-weight: bold;
    border-radius: 6px;
    padding: 0.5rem;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    transition: all 0.3s ease;
    &_Disabled {
        cursor: default !important;
        opacity: 0.3 !important;
    }
    &_Active {
        background: #f8cc37;
        cursor: default;
    }
    &_Assigned {
        background: #ffdcdc;
    }
    @media screen and (min-width: 768px) {
        padding: 15px;
        font-size: 30px;
    }
}

#calendarToday,
#calendarTomorrow {
    max-height: 500px;
    overflow-y: auto;
}