.kensaSummary {
    &_Title {
        margin: 0;
        padding: 1rem;
        font-size: 20px;
        border-bottom: 1px solid #ddd;
    }
    &_Content {
        padding: 1rem;
    }
    &_Row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-bottom: 1rem;
    }
    &_Col {
        flex: 0 0 auto;
        max-width: 100%;
        span {
            line-height: 30px;
        }
    }
    &_TotalLabel {
        font-size: 18px;
    }
    &_TotalValue {
        font-size: 18px;
        font-weight: bold;
    }
    &_TotalRow {
        padding-top: 1rem;
        border-top: 1px solid #ddd;
    }
    &_Box {
        padding: 1rem;
        background: #fff4e5;
        button {
            background: none !important;
        }
    }
}