.kensaHotline {
    color: #333;
    font-weight: bold;
    text-align: right;
    &_phone {
        margin: 0;
        font-size: 20px;
    }
    &_text {
        margin: 0;
        font-size: 16px;
    }
}

.kensaLogo {
    height: 40px;
    object-fit: cover;
}

.kensaNavbar {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}