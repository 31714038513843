.kensaForm {
    &_Action {
        &_Container {
            margin-top: auto;
            padding: 1rem;
            border-top: 1px solid #ddd;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
            background: #FFF;
            z-index: 1;
            @media screen and (min-width: 768px) {
                position: static;
                left: auto;
                right: auto;
                bottom: auto;
                box-shadow: none;
            }
        }
        &_Button {
            padding: 0.5rem 1rem;
            font-weight: bold;
        }
    }
}