.kensaConfirm {
    &_Modal {}
    &_ModalDialog {
        max-width: 900px !important;
    }
    &_PriceContainer {
        border: 1px solid #ddd;
        margin-bottom: 2rem;
    }
    &_PrepayContainer {
        display: flex;
        flex-flow: row wrap;
    }
    &_Term {
        margin-bottom: 1rem;
    }
    &_List {
        padding-left: 2rem;
    }
}